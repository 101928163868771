import React, { useState } from "react"
import css from "@styled-system/css"
import { navigate } from "gatsby"

import {
  Box,
  Button,
  ColorMode,
  H3,
  Flex,
  H6,
  Input,
  Textarea,
  Pattern,
  Wrapper,
  FileInput,
  Text,
} from "components"

function Form({ bg }) {
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState("")
  const [fullName, setFullName] = useState("")
  const [address, setAddress] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [postal, setPostal] = useState("")
  const [message, setMessage] = useState("")
  const [photo1, setPhoto1] = useState("")
  const [photo2, setPhoto2] = useState("")
  const [photo3, setPhoto3] = useState("")
  const [photo4, setPhoto4] = useState("")
  const [photo5, setPhoto5] = useState("")
  const [photo6, setPhoto6] = useState("")
  const [deficiencies, setDeficiencies] = useState([""])

  const encode = function(data) {
    const formData = new FormData()

    for (const key of Object.keys(data)) {
      formData.append(key, data[key])
    }

    return formData
  }

  const handleSubmit = e => {
    e.preventDefault()
    let error = false
    setError("")
    if (!email || !/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
      error = true
      setError("Email must be a valid email address")
    }
    if (!fullName) {
      error = true
      setError("Full name is required")
    }
    if (!address) {
      error = true
      setError("Your address is required")
    }
    if (!phone) {
      error = true
      setError("Phone number is required")
    }
    if (!postal) {
      error = true
      setError("Please enter a valid postal code")
    }
    if (!deficiencies[0]) {
      error = true
      setError("You must explain the concerns we are helping to fix.")
    }
    if (!processing && !error) {
      setProcessing(true)
      const form = e.target
      fetch("/", {
        method: "POST",
        body: encode({
          "form-name": form.getAttribute("name"),
          fullName: fullName,
          address: address,
          email: email,
          phone: phone,
          postal: postal,
          message: message,
          photo1: photo1,
          photo2: photo2,
          photo3: photo3,
          photo4: photo4,
          photo5: photo5,
          photo6: photo6,
          deficiency1: deficiencies[0] ? deficiencies[0] : "",
          deficiency2: deficiencies[1] && deficiencies[1],
          deficiency3: deficiencies[2] && deficiencies[2],
          deficiency4: deficiencies[3] && deficiencies[3],
          deficiency5: deficiencies[4] && deficiencies[4],
          deficiency6: deficiencies[5] && deficiencies[5],
          deficiency7: deficiencies[6] && deficiencies[6],
          deficiency8: deficiencies[7] && deficiencies[7],
          deficiency9: deficiencies[8] && deficiencies[8],
          deficiency10: deficiencies[9] && deficiencies[9],
          deficiency11: deficiencies[10] && deficiencies[10],
          deficiency12: deficiencies[11] && deficiencies[11],
          deficiency13: deficiencies[12] && deficiencies[12],
          deficiency14: deficiencies[13] && deficiencies[13],
          deficiency15: deficiencies[14] && deficiencies[14],
          deficiency16: deficiencies[15] && deficiencies[15],
          deficiency17: deficiencies[16] && deficiencies[16],
          deficiency18: deficiencies[17] && deficiencies[17],
          deficiency19: deficiencies[18] && deficiencies[18],
          deficiency20: deficiencies[19] && deficiencies[19],
        }),
      })
        .then(() => {
          setProcessing(false)
          navigate(form.getAttribute("action"))
        })
        .catch(error => () => {
          setProcessing(false)
          setError(error)
        })
    }
  }

  return (
    <Box
      as="form"
      name="serviceRequestForm"
      method="post"
      action="/thank-you/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
      css={css({ transition: "opacity 200ms ease-in" })}
      style={{
        opacity: processing ? 0.5 : 1,
        cursor: processing ? "busy" : "",
      }}
    >
      <Flex
        css={css({
          flexWrap: "wrap",
          m: [-2, -3],
          "> *": {
            width: ["100%", "50%"],
            p: [2, 3],
          },
        })}
      >
        <Box>
          <Flex
            css={css({
              flexWrap: "wrap",
              m: -2,
              "> *": {
                width: "100%",
                p: 2,
              },
            })}
          >
            <Box>
              <Input
                label={`Full name`}
                name="fullName"
                value={fullName}
                onChange={event => {
                  setFullName(event.target.value)
                }}
              />
            </Box>
            <Box>
              <Input
                label={`Email`}
                name="email"
                type="email"
                value={email}
                onChange={event => {
                  setEmail(event.target.value)
                }}
              />
            </Box>
            <Box>
              <Input
                label={`Phone`}
                type="phone"
                name="phone"
                value={phone}
                onChange={event => {
                  setPhone(event.target.value)
                }}
              />
            </Box>
            <Box>
              <Input
                label={`Postal code`}
                name="postal"
                value={postal}
                onChange={event => {
                  setPostal(event.target.value)
                }}
              />
            </Box>
            <Box>
              <Input
                label={`Home Address`}
                type="text"
                name="address"
                value={address}
                onChange={event => {
                  setAddress(event.target.value)
                }}
              />
            </Box>
            <Box>
              <Textarea
                label={`Message (Optional)`}
                type="text"
                name="message"
                value={message}
                onChange={event => {
                  setMessage(event.target.value)
                }}
                css={css({ resize: "none" })}
              />
            </Box>
          </Flex>
        </Box>
        <Box>
          {deficiencies.map((deficiency, index) => (
            <Box mb={3}>
              <Textarea
                label={`Concern #` + (index + 1)}
                rows="2"
                type="text"
                name={"deficiency" + (index + 1)}
                value={deficiency}
                onChange={event => {
                  let newDeficiences = JSON.parse(JSON.stringify(deficiencies))
                  newDeficiences[index] = event.target.value
                  setDeficiencies(newDeficiences)
                }}
                css={css({ resize: "none" })}
              />
            </Box>
          ))}
          {deficiencies.length < 20 && (
            <Button
              children={`Add another concern`}
              type="button"
              onClick={() => {
                let newDeficiences = JSON.parse(JSON.stringify(deficiencies))
                newDeficiences.push("")
                setDeficiencies(newDeficiences)
              }}
            />
          )}
          <Box mt={3}>
            <H6 children={`Photos (Recommended)`} mb={2} />
            <FileInput
              name="photo1"
              accept="image/*"
              onChange={event => {
                setPhoto1(event.target.files[0])
              }}
            />
            <FileInput
              name="photo2"
              accept="image/*"
              onChange={event => {
                setPhoto2(event.target.files[0])
              }}
            />
            <FileInput
              name="photo3"
              accept="image/*"
              onChange={event => {
                setPhoto3(event.target.files[0])
              }}
            />
            <FileInput
              name="photo4"
              accept="image/*"
              onChange={event => {
                setPhoto4(event.target.files[0])
              }}
            />
            <FileInput
              name="photo5"
              accept="image/*"
              onChange={event => {
                setPhoto5(event.target.files[0])
              }}
            />
            <FileInput
              name="photo6"
              accept="image/*"
              onChange={event => {
                setPhoto6(event.target.files[0])
              }}
            />
          </Box>
        </Box>
      </Flex>
      <input type="text" name="deficiency1" hidden />
      <input type="text" name="deficiency2" hidden />
      <input type="text" name="deficiency3" hidden />
      <input type="text" name="deficiency4" hidden />
      <input type="text" name="deficiency5" hidden />
      <input type="text" name="deficiency6" hidden />
      <input type="text" name="deficiency7" hidden />
      <input type="text" name="deficiency8" hidden />
      <input type="text" name="deficiency9" hidden />
      <input type="text" name="deficiency10" hidden />
      <input type="text" name="deficiency11" hidden />
      <input type="text" name="deficiency12" hidden />
      <input type="text" name="deficiency13" hidden />
      <input type="text" name="deficiency14" hidden />
      <input type="text" name="deficiency15" hidden />
      <input type="text" name="deficiency16" hidden />
      <input type="text" name="deficiency17" hidden />
      <input type="text" name="deficiency18" hidden />
      <input type="text" name="deficiency19" hidden />
      <input type="text" name="deficiency20" hidden />
      <Box mt={4}>
        <Button children={`Submit`} variant="reverse" type="submit" />
      </Box>
      {error && <Text children={error} mt={3} color="red" />}
    </Box>
  )
}

function ServiceRequest({ bg }) {
  return (
    <ColorMode mode="dark">
      <Pattern as="section" id="service-request" mt={[0, 5, 6]}>
        <Wrapper bg={bg || "brand.ochre.0"} flush>
          <Wrapper py={5}>
            <H3 mb={4}>Service request</H3>
            <Box>
              <Form />
            </Box>
          </Wrapper>
        </Wrapper>
      </Pattern>
    </ColorMode>
  )
}

export default ServiceRequest
