import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { SEO, PageHeader } from "components"
import { usePreviewData } from "utils"

import Description from "./_description"
import ServiceRequest from "./_serviceRequest"
import FAQs from "./_faqs"

function HomeCarePage() {
  const data = useStaticQuery(
    graphql`
      {
        prismicHomeCare {
          data {
            seo_title {
              text
            }
            seo_description {
              text
            }
            seo_image {
              url
              alt
            }
            hero_image {
              url
              alt

              fluid(maxWidth: 1600) {
                ...GatsbyPrismicImageFluid
              }
            }
            hero_video {
              url
            }
            hero_title {
              text
            }
            hero_subtitle {
              text
            }
            intro_description {
              html
            }
            form_title {
              text
            }
            form_description {
              html
            }
            form_color
            faq_title {
              text
            }
            faq_description {
              html
            }
            frequently_asked_questions {
              question
              answer {
                text
              }
            }
          }
        }
      }
    `
  )

  // Merge preview data with static query if possible
  const MERGED_DATA = usePreviewData(data)
  const { prismicHomeCare } = MERGED_DATA

  return (
    <>
      <SEO
        title={prismicHomeCare.data.seo_title}
        description={prismicHomeCare.data.seo_description}
        image={prismicHomeCare.data.seo_image}
      />
      <PageHeader
        image={prismicHomeCare.data.hero_image}
        video={prismicHomeCare.data.hero_video}
        title={prismicHomeCare.data.hero_title}
        subtitle={prismicHomeCare.data.hero_subtitle}
      />
      {prismicHomeCare.data.intro_description &&
        prismicHomeCare.data.intro_description.html && (
          <Description description={prismicHomeCare.data.intro_description} />
        )}
      {/* <ServiceRequest
        title={prismicHomeCare.data.form_title}
        description={prismicHomeCare.data.form_description}
        bg={prismicHomeCare.data.form_color}
      /> */}
      <FAQs
        title={prismicHomeCare.data.faq_title}
        description={prismicHomeCare.data.faq_description}
        faqs={prismicHomeCare.data.frequently_asked_questions}
      />
    </>
  )
}

export default HomeCarePage
