import React from "react"

import { Wrapper, RichText, Box } from "components"

function Description({ description }) {
  return (
    <>
      <Wrapper as="section" id="community-description" pt={[5, 6]}>
        <Box width="100%" maxWidth={800}>
          {description && (
            <RichText content={description} fontSize={[1, 2, 3]} />
          )}
        </Box>
      </Wrapper>
    </>
  )
}

export default Description
