import React from "react"
import css from "@styled-system/css"

import { RichText, AccordionGroup, Box, Flex, H1, Wrapper } from "components"

const FAQs = ({ title, description, faqs }) => (
  <Wrapper as="section" id="faqs" pt={[5, 6]} css={css({ overflow: "hidden" })}>
    <Flex
      flexWrap="wrap"
      css={css({
        m: -4,
        "> *": {
          p: 4,
        },
      })}
    >
      <Box width={["100%", null, null, 1 / 2]}>
        <Box maxWidth={420}>
          {title && title.text && <H1 children={title.text} />}
          {description && description.html && (
            <RichText content={description} mt={[3, 4]} />
          )}
        </Box>
      </Box>
      <Box width={["100%", null, null, 1 / 2]}>
        {faqs && (
          <AccordionGroup
            items={faqs.map(faq => {
              return {
                heading: faq.question,
                children: faq.answer && faq.answer.text,
              }
            })}
          />
        )}
      </Box>
    </Flex>
  </Wrapper>
)

export default FAQs
